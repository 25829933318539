"use strict";
import * as echarts from 'echarts';
import './theme/dark';
import './theme/light';
import watermarkImage from '../assets/dotlake-transparent-high-quality.png';
import watermarkImageDark from '../assets/dotlake_dark.png';

var currentTheme = localStorage.getItem('theme') || 'light';
let selectedWatermarkImage = currentTheme === 'dark' ? watermarkImageDark : watermarkImage;

// Define the color variable
const tooltipBackgroundColor = 'rgba(255, 255, 255, 0.8)';

let watermark = {
  type: 'image',
  id: 'watermark',
  bounding: 'raw',
  right: 'center',
  top: 'center',
  z: -1,
  style: {
    image: selectedWatermarkImage,
    width: 150,
    height: 150,
    opacity: .5
  }
};

const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
toggleSwitch.addEventListener('change', function(e) {
  currentTheme = e.target.checked ? 'dark' : 'light';
  localStorage.setItem('theme', currentTheme);
  watermark.style.image = currentTheme === 'dark' ? watermarkImageDark : watermarkImage;
});

function renameChain(chainName, relay = 'polkadot') {
  switch (chainName) {
    case 'alephzero':
      return 'aleph-zero';
    case 'bridgehub':
      return 'kusama-bridgehub';
    case 'collectives':
      return 'polkadot-collectives';
    case 'coretime':
      return 'kusama-coretime';
    case 'encointer':
      return 'kusama-encointer';
    case 'hydradx':
      return 'hydration';
    case 'origintrail':
      return 'neuroweb';
    case 'people':
      // Use the relay value to decide the name for the 'people' chain
      return relay === 'polkadot' ? 'polkadot-people' : 'kusama-people';
    case 'polkadot-hashed':
      return 'hashed';
    case 'polkadot-t3rn':
      return 't3rn';
    case 'statemint':
      return 'polkadot-asset hub';
    case 'polkadot_asset_hub':
      return 'polkadot-asset hub';
    case 'statemine':
      return 'kusama-asset hub';
    case 'kusama_asset_hub':
      return 'kusama-asset hub';
    default:
      return chainName;
  }
}

let customFormatter = function (params) {
  // Convert values to numbers to ensure proper formatting
  params.forEach(param => {
      param.value = Number(param.value);
  });
  params.sort((a, b) => b.value - a.value);

  let filteredParams = params;
  if (params.length >= 10) {
      filteredParams = params.filter(param => param.value !== 0);
  }

  let total = filteredParams.reduce((sum, param) => sum + param.value, 0);
  let tooltipContent = '<div><table class="tb">';
  if (filteredParams.length > 3) {
      tooltipContent += `<tr>
                          <th><b>Total:</b></th>
                          <th><b>${total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 3 })}</b></th>
                        </tr>`;
  }
  filteredParams.forEach(param => {
      tooltipContent += `<tr>
                          <td>${param.marker}${param.seriesName}:</td>
                          <td><b>${param.value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 3 })}</b></td>
                         </tr>`;
  });
  tooltipContent += '</table></div>';
  let header = params[0] ? params[0].axisValueLabel : '';
  return `<div>${header}<br/>${tooltipContent}</div>`;
}


export function barchart(config, data, chart) {
  const xName = config.freq == 'daily' ? 'date' : 'month';
  const yName = config.yName;
  const yTitle = config.title;
  const grouping = config.grouping === undefined ? 'chain' : config.grouping;
  const topN = config.topN === undefined ? 50 : config.topN;
  const type = config.type === undefined ? 'bar' : config.type;
  const stack = type === 'bar' ? 'total' : '';

  const colorScheme = get_chain_colors();

  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
  });

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq == 'daily' ? '2-digit' : undefined,
  });

  const formattedDateMap = new Map();
  data.forEach(item => {
    const originalDate = new Date(item[xName]);
    const formattedDate = dateFormatter.format(originalDate);
    item.formattedDate = formattedDate;
    formattedDateMap.set(item[xName], formattedDate);
  });

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort((a, b) => new Date(a) - new Date(b));

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {'other': otherSum});
    } else {
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: type,
    stack: stack,
    data: seriesData[chain],
    itemStyle: { 
      color: colorScheme[chain],
      opacity: 0.8
    },
  }));

  let legendData = chains;
  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '80%', top: '6%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%' 
  };

  let gridOptions = chains.length > 7 ? { 
    left: '2%', top: '2%', right: '22%', bottom: '5%', containLabel: true 
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  }

  let selectorOptions = chains.length > 3 ? [{
      type: 'all',
      title: 'Select All'
  }, {
      type: 'inverse',
      title: 'Invert Selection'
  }] : [];

  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: ['bar', 'line', 'stack'] },
      },
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: legendData,
      orient: 'horizontal',
      padding: 1,
      textStyle: {
        fontSize: '10',
      },
      selected: legendData.reduce((obj, item) => {
          obj[item] = true; // Initially, all items are selected
          return obj;
      }, {}),
      selector: selectorOptions,
      ...legendPositionAndOrientation 
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates.map(date => formattedDateMap.get(date)), axisPointer: { type: 'shadow' } },
    yAxis: { name: yTitle, type: 'value' },
    series: series,
  };

  chart.setOption(option, true);
}

function roundUpToSignificantFigure(num, significantFigure) {
  const magnitude = Math.pow(10, significantFigure - Math.ceil(Math.log10(Math.abs(num))));
  return Math.ceil(num * magnitude) / magnitude;
}

function roundUpToSignificantFigure(num, significantFigure) {
  const magnitude = Math.pow(10, significantFigure - Math.ceil(Math.log10(Math.abs(num))));
  return Math.ceil(num * magnitude) / magnitude;
}

export function line_chart(config, data, chart) {
  const xName = config.xName;
  const yName = config.yName;
  const yTitle = config.title;
  const grouping = config.grouping === undefined ? 'chain' : config.grouping;
  const topN = config.topN === undefined ? 50 : config.topN;
  const type = config.type === undefined ? 'line' : config.type;
  const stack = type === 'bar' ? 'total' : '';
  const showLegend = config.grouping === undefined ? false : true;

  const colorScheme = get_chain_colors();

  data.forEach(item => {
    item[yName] = parseFloat(item[yName]);
    if (!item.hasOwnProperty('chain')) {
      item.chain = yTitle;
    }
  });

  const dates = Array.from(new Set(data.map(item => item[xName]))).sort();

  let aggregatedData = {};
  data.forEach(item => {
    const xSeries = item[xName];
    const chain = item[grouping];
    if (!aggregatedData[xSeries]) {
      aggregatedData[xSeries] = {};
    }
    if (!aggregatedData[xSeries][chain]) {
      aggregatedData[xSeries][chain] = 0;
    }
    aggregatedData[xSeries][chain] += item[yName];
  });

  let topChainsSet = new Set();
  Object.keys(aggregatedData).forEach(month => {
    let chains = Object.keys(aggregatedData[month]);
    chains.sort((a, b) => aggregatedData[month][b] - aggregatedData[month][a]);
    let topChains = chains.slice(0, topN);
    topChains.forEach(chain => topChainsSet.add(chain));

    if (chains.length > topN) {
      let otherSum = chains.slice(topN).reduce((sum, chain) => sum + aggregatedData[month][chain], 0);
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {'other': otherSum});
    } else {
      aggregatedData[month] = topChains.reduce((obj, key) => {
        obj[key] = aggregatedData[month][key];
        return obj;
      }, {});
    }
  });

  let chains = Array.from(topChainsSet);
  if (Object.values(aggregatedData).some(monthData => monthData.hasOwnProperty('other'))) {
    chains.push('other');
  }

  chains.sort((a, b) => {
    let aSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[a] || 0), 0);
    let bSum = Object.values(aggregatedData).reduce((sum, curr) => sum + (curr[b] || 0), 0);
    return bSum - aSum;
  });

  chains.forEach((chain, index) => {
    if (!colorScheme[chain]) {
      colorScheme[chain] = generatedColors[index % generatedColors.length];
    }
  });

  let seriesData = {};
  chains.forEach(chain => {
    seriesData[chain] = new Array(dates.length).fill(0);
  });

  Object.keys(aggregatedData).forEach(month => {
    const monthIndex = dates.indexOf(month);
    chains.forEach(chain => {
      seriesData[chain][monthIndex] = aggregatedData[month][chain] || 0;
    });
  });

  let series = chains.map(chain => ({
    name: chain,
    type: type,
    stack: stack,
    areaStyle: {},
    emphasis: {
      focus: 'series'
    },
    data: seriesData[chain],
    itemStyle: { 
      color: colorScheme[chain],
      opacity: 0.8 
    },
  }));

  let legendData = chains;

  let legendPositionAndOrientation = chains.length > 7 ? {
    orient: 'vertical', left: '80%', top: '6%', height: '85%'
  } : {
    orient: 'horizontal', left: 'center', bottom: '2%'
  };

  let gridOptions = chains.length > 7 ? { 
    left: '2%', top: '2%', right: '22%', bottom: '0%', containLabel: true 
  } : {
    left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true 
  };

  let selectorOptions = chains.length > 3 ? [{
      type: 'all',
      title: 'Select All'
  }, {
      type: 'inverse',
      title: 'Invert Selection'
  }] : [];

  let yAxisMax = config.elementId === 'general_stablecoins_usdt' ? function(value) {
    return roundUpToSignificantFigure(value.max * 1.1, 2);
  } : undefined;

  let option = {
    toolbox: {
      show: true,
      feature: {
        magicType: { type: ['bar', 'line', 'stack'] },
      },
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      confine: true,
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      show: showLegend,
      data: legendData,
      orient: 'vertical',
      padding: 3,
      selected: legendData.reduce((obj, item) => {
          obj[item] = true; // Initially, all items are selected
          return obj;
      }, {}),
      selector: selectorOptions,
      ...legendPositionAndOrientation
    },
    graphic: watermark,
    grid: gridOptions,
    xAxis: { type: 'category', data: dates, axisPointer: { type: 'shadow' } },
    yAxis: { 
      name: yTitle, 
      type: 'value', 
      max: yAxisMax 
    },
    series: series,
  };

  chart.setOption(option, true);
}

export function inflation_bar_chart(config, data, chart) {
  const xName = 'referendum_name';
  const yName = 'votes';
  const grouping = 'vote_type'; // Group by vote_type (ayes, nays)
  
  // Extract referendum names and votes
  const referendumNames = Array.from(new Set(data.map(item => item[xName])));
  
  // Organize the data by referendum and vote_type
  let groupedData = {};
  data.forEach(item => {
    if (!groupedData[item.referendum_index]) {
      groupedData[item.referendum_index] = { ayes: 0, nays: 0 };
    }
    groupedData[item.referendum_index][item.vote_type] = item[yName];
  });

  const ayesData = referendumNames.map(name => {
    const referendum = data.find(item => item[xName] === name);
    return groupedData[referendum.referendum_index]['ayes'];
  });

  const naysData = referendumNames.map(name => {
    const referendum = data.find(item => item[xName] === name);
    return groupedData[referendum.referendum_index]['nays'];
  });

  let option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      formatter: customFormatter
    },
    legend: {
      data: ['Ayes', 'Nays']
    },
    graphic: watermark,
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: referendumNames, // X-axis shows referendum names
      axisLabel: {
        interval: 0, // Ensures all labels are shown
      },
    },
    yAxis: {
      type: 'value',
      name: 'Votes in DOT'
    },
    series: [
      {
        name: 'Ayes',
        type: 'bar',
        data: ayesData,
        itemStyle: {
          color: colors.success, 
        },
        barGap: 0, // Ensures bars are next to each other
        barCategoryGap: '20%',
      },
      {
        name: 'Nays',
        type: 'bar',
        data: naysData,
        itemStyle: {
          color: colors.failure,
        },
        barGap: 0, // Ensures bars are next to each other
        barCategoryGap: '20%',
      },
    ]
  };

  chart.setOption(option);
}

export function horizontal_barchart(config, data) {
  const xName = config.xName; // Represents the count or value field
  const yName = config.yName; // Represents the main category (e.g., chain)
  const grouping = config.grouping; // Represents the sub-category (e.g., language)

  // Ensure the value field is treated as a number
  data.forEach(item => {
    item[xName] = parseFloat(item[xName]);
  });

  // Aggregate data by main category (yName) and sub-category (grouping)
  const aggregatedData = {};
  data.forEach(item => {
    if (!aggregatedData[item[yName]]) {
      aggregatedData[item[yName]] = {};
    }
    if (!aggregatedData[item[yName]][item[grouping]]) {
      aggregatedData[item[yName]][item[grouping]] = 0;
    }
    aggregatedData[item[yName]][item[grouping]] += item[xName];
  });

  // Extract unique main categories (e.g., chains) and sub-categories (e.g., languages)
  const yCategories = Object.keys(aggregatedData).sort();
  const groupings = Array.from(new Set(data.map(item => item[grouping]))).sort();

  // Prepare series data for each sub-category (grouping)
  const series = groupings.map(group => ({
    name: group,
    type: 'bar',
    stack: 'total',
    label: {
      show: true
    },
    emphasis: {
      focus: 'series'
    },
    data: yCategories.map(yCat => aggregatedData[yCat][group] || 0)
  }));

  // Chart configuration
  const option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: groupings,
      orient: 'horizontal',
      padding: 1,
      textStyle: {
        fontSize: '10',
      },
      orient: 'horizontal', left: 'center', bottom: '2%' 
    },
    graphic: watermark,
    grid: { left: '2%', top: '2%', right: '2%', bottom: '10%', containLabel: true },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: yCategories
    },
    series: series
  };

  window[config.elementId].setOption(option, true);
}


export function donut_chart(config, data, chart) {
  const yName = config.yName;
  const currentTheme = localStorage.getItem('theme') || 'light';
  const watermark = {
      type: 'image',
      id: 'watermark',
      bounding: 'raw',
      right: 'center',
      top: 'center',
      z: -1,
      style: {
          image: currentTheme === 'dark' ? watermarkImageDark : watermarkImage,
          width: 150,
          height: 150,
          opacity: 0.5
      }
  };
  
  let aggregatedData = {};
  
  data.forEach(item => {
      const chain = item[config.grouping] || 'unknown';
      aggregatedData[chain] = (aggregatedData[chain] || 0) + parseFloat(item[yName]);
  });
  
  let seriesData = Object.keys(aggregatedData).map(key => ({
      value: aggregatedData[key],
      name: key
  }));
  
  let option = {
      tooltip: {
          backgroundColor: tooltipBackgroundColor,
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: {
          orient: 'vertical',
          left: 'left',
          data: Object.keys(aggregatedData)
      },
      series: [
          {
              name: config.title,
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                  borderRadius: 10,
                  borderColor: '#fff',
                  borderWidth: 2
              },
              label: {
                  show: false,
                  position: 'center'
              },
              emphasis: {
                  label: {
                      show: true,
                      fontSize: '16',
                      fontWeight: 'bold'
                  }
              },
              labelLine: {
                  show: false
              },
              data: seriesData
          }
      ],
      graphic: watermark
  };
  
  chart.setOption(option, true);
}
  
const generatedColors = Array.from({ length: 8}, (_, index) => {
    const hue = (index * 137.508) % 360;
    const saturation = 50 + Math.random() * 40;
    const lightness = 65 + Math.random() * 20;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
});

// used by loadCSVData
function convertToMonthEndDate(dateString) {
  if (dateString.length === 7) {
      const year = parseInt(dateString.substring(0, 4));
      const month = parseInt(dateString.substring(5, 7));
      const endDate = new Date(year, month, 0).getDate();
      return `${dateString}-${endDate.toString().padStart(2, '0')}`;
  }
  return dateString;
}
  
export function loadCSVData(csvPath, callback) {
  fetch(csvPath)
    .then(response => response.text())
    .then(data => {
      const rows = data.split('\n').filter(row => row.trim() !== '');
      const headers = rows[0].split(',');
      let dateColumnIndex = headers.indexOf('month');
      if (dateColumnIndex === -1) {
          dateColumnIndex = headers.indexOf('date');
      }
  
      const csvArray = rows.slice(1).map(row => {
      const values = row.split(',');
      const entry = headers.reduce((obj, header, index) => {
        
        if (header === 'month' || header === 'date') {
          obj[header] = convertToMonthEndDate(values[index]);
        
        } else if (header === 'chain') { 
          obj[header] = renameChain(values[index]);
        
        } else {
          obj[header] = values[index];
        
        }
        return obj;
      }, {});
      return entry;
    });

    if (dateColumnIndex !== -1) {
        csvArray.sort((a, b) => {
            const dateA = new Date(a[headers[dateColumnIndex]]);
            const dateB = new Date(b[headers[dateColumnIndex]]);
            return dateA - dateB;
        });
    }

    return csvArray;
  })
  .then(processedData => {
    callback(processedData);
  })
  .catch(error => {
    console.error('Error loading CSV data:', error);
  });
}

export function loadJSONData(jsonPath, relay, callback, chart) {
  fetch(jsonPath)
    .then(response => response.text())
    .then(data => {
      const jsonArray = data.split('\n')
                            .filter(line => line.trim() !== '')
                            .map(JSON.parse);
      // console.log("data:",data);                      
      return jsonArray;
    })
    .then(generalData => {
      // console.log("data:",generalData);
      // Determine the appropriate filter key based on the data
      const filterKey = generalData[0] && 'relay_chain' in generalData[0] ? 'relay_chain' :
                        (generalData[0] && 'chain_name' in generalData[0] ? 'chain_name' : null);

      let filteredData = filterKey ? generalData.filter(element => element[filterKey] === relay) : generalData;
      
      return filteredData;
    })
    .then(filteredData => {
      // if exactly two numeric field, create a `combined` field as well (i.e., Substrate + EVM)
      let enhancedData = filteredData.map(element => {
        // Rename some chains...
        if (element.chain) {
          element.chain = renameChain(element.chain, element.relahy_chain);
        }
        return element;
      });
      return enhancedData;
    })
    .then(enhancedData => {
      callback(enhancedData, chart); // Invoke the callback with the enhanced data and chart instance
    })
    .catch(error => {
      console.error('Error loading JSON data:', error);
    });
}


function valueFormatter(value) {
  var suffixes = ["", "K", "M", "B", "T"];
  var suffixIndex = 0;
  while (value >= 1000 && suffixIndex < suffixes.length - 1) {
    value /= 1000;
    suffixIndex++;
  }
  return value.toFixed(0) + " " + suffixes[suffixIndex];
}

export function createEchartElement(elementId, theme) {
  const container = document.getElementById(elementId);
  if (!container) return null;
  const existingChart = echarts.getInstanceByDom(container);
  if (existingChart) {
    echarts.dispose(existingChart);
  }
  const chart = echarts.init(container, theme);
  window[elementId] = chart;
  return chart;
}

// Treasuries
export function treasuries(config, data, chart) {

  const markLineData = [
    { name: ' Yearly "Trend" line', coord: [data[0].date, data[0].monthly_average_treasury_balance] },
    { name: '', coord: [data[data.length - 1].date, data[data.length - 1].monthly_average_treasury_balance] }
  ];

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedDates = data.map(item => {
    const originalDate = new Date(item.date);
    return dateFormatter.format(originalDate);
  });
  
  var option = {
    title: {},
    graphic: watermark,
    tooltip: {backgroundColor: tooltipBackgroundColor},
    xAxis: {
      type: 'category',
      data: formattedDates
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: valueFormatter
      }
    },
    series: [{
      name: 'Monthly Treasury Balance',
      type: 'line',
      areaStyle: {},
      data: data.map(item => item.monthly_average_treasury_balance),
      color: '#E6007A',
      markLine: {
        label: { position: 'insideMiddleTop' },
        lineStyle: { type: 'dashed' },
        data: [markLineData]
      }
    }]
  };

  chart.setOption(option, true);
}

export function treasury_assets(config, data, chart) {
  var colorScheme = {
    'DOT': colors.polkadot
  };

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Extract and format unique months
  let uniqueMonths = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  const formattedMonths = uniqueMonths.map(month => {
    const date = new Date(month);
    return dateFormatter.format(date);
  });

  // Process data to aggregate by month and asset
  var aggregatedData = {};
  uniqueMonths.forEach(month => {
    data.forEach(item => {
      if (item.month.slice(0, 10) === month) {
        if (!aggregatedData[month]) {
          aggregatedData[month] = {};
        }
        if (!aggregatedData[month][item.asset]) {
          aggregatedData[month][item.asset] = 0;
        }
        aggregatedData[month][item.asset] += parseFloat(item.balance_usd);
      }
    });
  });

  // Prepare series data
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
    Object.keys(aggregatedData[month]).forEach(asset => {
      if (!seriesData[asset]) {
        seriesData[asset] = new Array(uniqueMonths.length).fill(0);
      }
      var monthIndex = uniqueMonths.indexOf(month);
      seriesData[asset][monthIndex] = aggregatedData[month][asset];
    });
  });

  var series = [];
  Object.keys(seriesData).forEach(function(asset) {
    series.push({
      name: asset,
      type: 'line',
      stack: 'total',
      areaStyle: {},
      data: seriesData[asset],
      color: colorScheme[asset],
      itemStyle: {
        opacity: 0.8
      }
    });
  });

  var option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'shadow'
      },
      formatter: function(params) {
        // Sort params to ensure DOT is always at the top
        params.sort((a, b) => {
          if (a.seriesName === 'DOT') return -1;
          if (b.seriesName === 'DOT') return 1;
          return 0;
        });

        let tooltipText = params[0].axisValue + '<br/>';
        let total = 0;
        params.forEach(item => {
          tooltipText += item.marker + item.seriesName + ': $' + item.value.toLocaleString() + '<br/>';
          total += item.value;
        });
        tooltipText += '<br/>Total: $' + total.toLocaleString();
        return tooltipText;
      }
    },
    legend: {
      bottom: '0%',
      left: 'center',
      data: Object.keys(seriesData)
    },
    graphic: watermark,
    grid: {
      left: '3%',
      right: '4%',
      bottom: '9%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: {
      name: 'USD',
      type: 'value'
    },
    series: series
  };

  chart.setOption(option, true);
}

export function treasury_assets_native(config, data, chart) {
  var colorScheme = {
    'DOT': colors.polkadot
  };

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Extract and format unique months
  let uniqueMonths = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  const formattedMonths = uniqueMonths.map(month => {
    const date = new Date(month);
    return dateFormatter.format(date);
  });

  // Process data to aggregate by month and asset
  var aggregatedData = {};
  uniqueMonths.forEach(month => {
    data.forEach(item => {
      if (item.month.slice(0, 10) === month) {
        if (!aggregatedData[month]) {
          aggregatedData[month] = {};
        }
        if (!aggregatedData[month][item.asset]) {
          aggregatedData[month][item.asset] = 0;
        }
        aggregatedData[month][item.asset] += parseFloat(item.balance);
      }
    });
  });

  // Prepare series data
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
    Object.keys(aggregatedData[month]).forEach(asset => {
      if (!seriesData[asset]) {
        seriesData[asset] = new Array(uniqueMonths.length).fill(0);
      }
      var monthIndex = uniqueMonths.indexOf(month);
      seriesData[asset][monthIndex] = aggregatedData[month][asset];
    });
  });

  var series = [];
  Object.keys(seriesData).forEach(function(asset) {
    series.push({
      name: asset,
      type: 'bar',
      data: seriesData[asset],
      color: colorScheme[asset],
      itemStyle: {
        opacity: 0.8
      }
    });
  });

  var option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'shadow'
      },
      formatter: function(params) {
        // Sort params to ensure DOT is always at the top
        params.sort((a, b) => {
          if (a.seriesName === 'DOT') return -1;
          if (b.seriesName === 'DOT') return 1;
          return 0;
        });

        let tooltipText = params[0].axisValue + '<br/>';
        params.forEach(item => {
          tooltipText += item.marker + item.seriesName + ': ' + item.value.toLocaleString() + '<br/>';
        });
        return tooltipText;
      }
    },
    legend: {
      bottom: '0%',
      left: 'center',
      data: Object.keys(seriesData)
    },
    graphic: watermark,
    grid: {
      left: '3%',
      right: '4%',
      bottom: '9%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: {
      name: 'Tokens',
      type: 'value'
    },
    series: series
  };

  chart.setOption(option, true);
}




// EOYR charts // The stuff below this won't need much editing
const colors = {
  polkadot: '#E6007A',
  kusama: '#000000',
  emphasis: '#00B2FF',
  success: '#27ae60',
  failure: 'red',
  neutral: '#552BBF',
  background: '#95a5a6',
  heat: "#e67e22"
}

export function staking_rate(config, data, chart) {
  let months = data.map(item => item.month.slice(0, 10));
  let totalDotIssuance = data.map(item => parseFloat(item.total_dot_issuance));
  let totalDotStaked = data.map(item => parseFloat(item.total_dot_staked));
  let percentageStaked = data.map(item => 100 * parseFloat(item.percentage_staked));

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedDates = data.map(item => {
    const originalDate = new Date(item.month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Total DOT Issuance', 'Total DOT Staked', 'Percentage Staked']
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    graphic: watermark,
    xAxis: {
      type: 'category',
      data: formattedDates
    },
    yAxis: [
      {
        type: 'value',
        name: 'DOT',
      },
      {
        type: 'value',
        name: 'Percentage',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Total DOT Issuance',
        type: 'bar',
        data: totalDotIssuance,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Total DOT Staked',
        type: 'bar',
        data: totalDotStaked,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Percentage Staked',
        type: 'line',
        yAxisIndex: 1,
        data: percentageStaked,
        color: colors.emphasis,
        itemStyle: {
          opacity: 0.8
        }
      }
    ]
  };

  chart.setOption(option, true);
}

export function nominators_validators(config, data, chart) {
  let months = data.map(item => item.month.slice(0, 10));
  let totalNominators = data.map(item => parseFloat(item.number_of_nominators));
  let totalActiveValidators = data.map(item => parseFloat(item.number_of_active_validators));
  let totalWaitingValidators = data.map(item => parseFloat(item.number_of_waiting_validators));

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedDates = data.map(item => {
    const originalDate = new Date(item.month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Number of Nominators', 'Number of active Validators', 'Number of waiting Validators']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: {
      type: 'category',
      data: formattedDates
    },
    yAxis: [
      {
        type: 'value',
        name: 'Active / Waiting Validators',
      },
      {
        type: 'value',
        name: 'Nominators',
      }
    ],
    series: [
      {
        name: 'Number of active Validators',
        type: 'bar',
        data: totalActiveValidators,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Number of waiting Validators',
        type: 'bar',
        data: totalWaitingValidators,
        color: colors.background,
        itemStyle: {
          opacity: 0.8
        }
        },
      {
        name: 'Number of Nominators',
        type: 'line',
        data: totalNominators,
        yAxisIndex: 1,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      }
    ]
  };

  chart.setOption(option, true);
}

export function nakamoto_coefficient(config, data, chart) {
  let months = data.map(item => item.month.slice(0, 10));
  let nakamotoCoefficient = data.map(item => parseFloat(item.nakamoto_coefficient));

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedDates = data.map(item => {
    const originalDate = new Date(item.month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Nakamoto Coefficient']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: {
      type: 'category',
      data: formattedDates
    },
    yAxis: [
      {
        type: 'value',
        name: 'Nakamoto Coefficient',
      },
    ],
    series: [
      {
        name: 'Nakamoto Coefficient',
        type: 'line',
        areaStyle: {
          color: colors.polkadot,
          opacity: 0.5
        },
        data: nakamotoCoefficient,
        color: colors.polkadot 
      },
    ]
  };

  chart.setOption(option, true);
}

export function min_max_nominators_validators_stake(config, data, chart) {
  let months = data.map(item => item.month.slice(0, 10));
  let maxStake = data.map(item => parseFloat(item.max_validator_stake));
  let diffPercentTotalStake = data.map(item => 100 * parseFloat(item.difference_in_percent_of_total_stake));
  let minStake = data.map(item => parseFloat(item.min_validator_stake));
  

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedDates = data.map(item => {
    const originalDate = new Date(item.month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Maximum Stake', 'Minimum Stake', 'Difference in % of total stake']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: {
      type: 'category',
      data: formattedDates
    },
    yAxis: [
      {
        type: 'value',
        name: 'Stake (DOT)',
      },
      {
        type: 'value',
        name: 'Difference in % of total stake',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Minimum Stake',
        type: 'bar',
        data: minStake,
        color: colors.emphasis,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Maximum Stake',
        type: 'bar',
        data: maxStake,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Difference in % of total stake',
        type: 'line',
        data: diffPercentTotalStake,
        yAxisIndex: 1,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      }
    ]
  };
  
  chart.setOption(option, true);
}
  
export function treasury_balance(config, data, chart) {
  let months = data.map(item => item.month.slice(0, 10));
  let treasuryBalance = data.map(item => parseFloat(item.treasury_balance_dot));
  let percentOfTotalBalance = data.map(item => parseFloat(item.percent_of_total_issuance));
  

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedDates = data.map(item => {
    const originalDate = new Date(item.month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Treasury Balance', 'Percent of Total Issuance']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: {
      type: 'category',
      data: formattedDates
    },
    yAxis: [
      {
        type: 'value',
        name: 'Balance (DOT)',
      },
      {
        type: 'value',
        name: 'Percent of total stake',
        axisLabel: {
          formatter: '{value} %'
        }
      }
    ],
    series: [
      {
        name: 'Treasury Balance',
        type: 'bar',
        data: treasuryBalance,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Percent of Total Issuance',
        type: 'line',
        data: percentOfTotalBalance,
        yAxisIndex: 1,
        color: colors.emphasis,
        itemStyle: {
          opacity: 0.8
        }
      }
    ]
  };
  
  chart.setOption(option, true);
}
  
export function treasury_flows(config, data, chart) {
  var colorScheme = {
    'proposals': colors.emphasis,
    'inflation': colors.polkadot
  };

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Extract and format unique months
  let uniqueMonths = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  const formattedMonths = uniqueMonths.map(month => {
    const date = new Date(month);
    return dateFormatter.format(date);
  });

  // Process data to aggregate by month and type
  var aggregatedData = {};
  uniqueMonths.forEach(month => {
    data.forEach(item => {
      if (item.month.slice(0, 10) === month) {
        if (!aggregatedData[month]) {
          aggregatedData[month] = {};
        }
        if (!aggregatedData[month][item.type]) {
          aggregatedData[month][item.type] = 0;
        }
        aggregatedData[month][item.type] += parseFloat(item.sum_of_dot);
      }
    });
  });

  // Prepare series data
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
    Object.keys(aggregatedData[month]).forEach(type => {
      if (!seriesData[type]) {
        seriesData[type] = new Array(uniqueMonths.length).fill(0);
      }
      var monthIndex = uniqueMonths.indexOf(month);
      seriesData[type][monthIndex] = aggregatedData[month][type];
    });
  });

  var series = [];
  Object.keys(seriesData).forEach(function(type) {
    series.push({
      name: type,
      type: 'bar',
      stack: 'total',
      data: seriesData[type],
      itemStyle: {
        color: colorScheme[type],
        opacity: 0.8
      }
    });
  });

  var sortedSeriesData = {};
  uniqueMonths.forEach(month => {
    let monthData = [];
    Object.keys(aggregatedData[month]).forEach(chain => {
      monthData.push({
        chain: chain,
        value: aggregatedData[month][chain]
      });
    });

    monthData.sort((a, b) => b.value - a.value);
    sortedSeriesData[month] = monthData;
  });

  var option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'shadow'
      },
      formatter: customFormatter
    },
    legend: {
      bottom: '0%',
      left: 'center',
      data: Object.keys(seriesData)
    },
    graphic: watermark,
    grid: {
      left: '3%',
      right: '4%',
      bottom: '9%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: {
      name: 'DOT',
      type: 'value'
    },
    series: series
  };

  chart.setOption(option, true);
}

export function referenda_by_outcome_history(config, data, chart) {
  // Color scheme for different outcomes
  var colorScheme = {
    'Approved': colors.success,
    'Rejected': colors.failure,
    'TimedOut': colors.neutral,
    'Cancelled': colors.background,
    'Ongoing': colors.warning,
  };

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Process data to aggregate by month and outcome
  let aggregatedData = {};
  let months = new Set();
  data.forEach(item => {
    const month = item.month;
    const outcome = item.outcome;
    const value = parseInt(item.number_of_referenda);

    if (!aggregatedData[month]) {
      aggregatedData[month] = {};
    }
    if (!aggregatedData[month][outcome]) {
      aggregatedData[month][outcome] = 0;
    }
    aggregatedData[month][outcome] += value;
    months.add(month);
  });

  // Convert months to a sorted array and format them
  const sortedMonths = Array.from(months).sort();
  const formattedMonths = sortedMonths.map(month => {
    const date = new Date(month);
    return dateFormatter.format(date);
  });

  // Get all unique outcomes
  const outcomes = Object.keys(colorScheme);

  // Prepare raw data and total data for stacking
  let rawData = outcomes.map(outcome => {
    return sortedMonths.map(month => aggregatedData[month] && aggregatedData[month][outcome] ? aggregatedData[month][outcome] : 0);
  });

  let totalData = sortedMonths.map((month, index) => {
    return outcomes.reduce((sum, outcome) => sum + (rawData[outcomes.indexOf(outcome)][index] || 0), 0);
  });

  // Prepare series data
  const series = outcomes.map((outcome, sid) => {
    return {
      name: outcome,
      type: 'bar',
      stack: 'total',
      barWidth: '60%',
      label: {
        show: true,
        position: 'inside',
        formatter: (params) => {
          const percent = totalData[params.dataIndex] > 0 ? (params.value * 100).toFixed(1) : 0;
          return `${percent}%`;
        }
      },
      itemStyle: {
        color: colorScheme[outcome],
        opacity: 0.8
      },
      data: rawData[sid].map((d, did) => totalData[did] <= 0 ? 0 : d / totalData[did])
    };
  });

  // Configure the chart option
  var option = {
    legend: {
      bottom: '0%',
      left: 'center'
    },
    grid: {
      left: 100,
      right: 100,
      top: 50,
      bottom: 50
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: { type: 'shadow' },
      formatter: function(params) {
        // Calculate the total value
        let totalValue = params.reduce((sum, item) => sum + (item.value * totalData[item.dataIndex]), 0);
        
        // Start the tooltip text with the date
        let tooltipText = `${params[0].axisValue}<br/>`;
    
        // Add each series' information to the tooltip text
        params.forEach(item => {
            const percent = item.value > 0 ? (item.value * 100).toFixed(1) : 0;
            tooltipText += `${item.marker} ${item.seriesName}: ${percent}% (${(item.value * totalData[item.dataIndex]).toFixed(2)})<br/>`;
        });
    
        // Add the total value at the end
        tooltipText += `Total: ${totalValue.toFixed(2)}`;
    
        return tooltipText;
      }
    },
    xAxis: {
      type: 'category',
      data: formattedMonths,
      axisLabel: {
        formatter: value => value // Ensure the formatted month is displayed as it is
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => `${(value * 100).toFixed(0)}%`
      }
    },
    series: series,
    graphic: watermark
  };

  // Set the option to the chart
  chart.setOption(option, true);
}

export function average_votes_gov1vsopengov(config, data, chart) {
  var colorScheme = {
    'Gov 1': colors.emphasis,
    'Open Gov': colors.polkadot,
  };

  let averageVotes = data.map(item => ({
      value: parseFloat(item.average_votes),
      itemStyle: {
          color: colorScheme[item.gov_type],
          opacity: 0.8
      }
  }));
  let govType = data.map(item => item.gov_type);

  var option = {
    title: {
        text: 'Gov Type and Average Votes'
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'shadow'
      },
      formatter: customFormatter
    },
    xAxis: {
        type: 'value'
    },
    yAxis: {
        type: 'category',
        data: govType
    },
    series: [
        {
            name: 'Average Votes',
            type: 'bar',
            data: averageVotes,
        }
    ]
  };

  chart.setOption(option, true);
}

export function number_referenda_by_origin(config, data, chart) {
  var colorScheme = {
    'BigSpender': colors.polkadot,
    'MediumSpender': colors.emphasis,
    'SmallSpender': colors.success,
    'Root': colors.kusama
  };

  const option = {
      tooltip: {
          backgroundColor: tooltipBackgroundColor,
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: {
          orient: 'vertical',
          left: 'left',
          data: data.map(item => item.origin)
      },
      graphic: watermark,
      series: [
          {
              name: 'Origin Count',
              type: 'pie',
              radius: ['40%', '70%'],
              data: data.map((item, index) => {
                  return {
                      value: item.origin_count,
                      name: item.origin,
                      itemStyle: {
                          color: colorScheme[item.origin],
                          opacity: 0.8
                      }
                  };
              }),
              emphasis: {
                  itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }
          }
      ]
  };

  chart.setOption(option, true);
}

export function number_of_votes_by_duration_of_lock(config, data, chart) {
  var colorScheme = {
    'Locked6x': colors.polkadot,
    'Locked5x': colors.emphasis,
    'Locked4x': colors.success,
    'None': colors.background
  };

  const option = {
      tooltip: {
          backgroundColor: tooltipBackgroundColor,
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: {
          orient: 'vertical',
          left: 'left',
          data: data.map(item => item.conviction)
      },
      graphic: watermark,
      series: [
          {
              name: 'Origin Count',
              type: 'pie',
              radius: ['40%', '70%'],
              data: data.map((item, index) => {
                  return {
                      value: item.conviction_count,
                      name: item.conviction,
                      itemStyle: {
                          color: colorScheme[item.conviction],
                          opacity: 0.8
                      }
                  };
              }),
              emphasis: {
                  itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
              }
          }
      ]
  };

  chart.setOption(option, true);
}

function aggregateVotersByMonthAndType(data) {
  const result = data.reduce((acc, item) => {
    if (!acc[item.month]) {
      acc[item.month] = {direct: 0, delegated: 0, number_of_referenda: 0};
    }
    if (item.vote_type === 'direct') {
      acc[item.month].direct += parseInt(item.number_of_voters, 10);
    } else if (item.vote_type === 'delegated') {
      acc[item.month].delegated += parseInt(item.number_of_voters, 10);
    }
    acc[item.month].number_of_referenda = parseInt(item.number_of_referenda, 10);

    return acc;
  }, {});

  return result;
}

export function monthly_participation(config, data, chart) {
  let aggregatedVotes = aggregateVotersByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];

  Object.entries(aggregatedVotes).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedMonths = months.map(month => {
    const originalDate = new Date(month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct Voters', 'Delegated Voters', 'Number of Referenda']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Participation',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct Voters',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Delegated Voters',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Number of Referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  chart.setOption(option, true);
}


function aggregateDistinctByMonthAndType(data) {
  const result = data.reduce((acc, item) => {
    if (!acc[item.month]) {
      acc[item.month] = {direct: 0, delegated: 0, number_of_referenda: 0};
    }
    if (item.vote_type === 'direct') {
      acc[item.month].direct += parseInt(item.number_of_distinct_voters, 10);
    } else if (item.vote_type === 'delegated') {
      acc[item.month].delegated += parseInt(item.number_of_distinct_voters, 10);
    }
    acc[item.month].number_of_referenda = parseInt(item.number_of_referenda, 10);

    return acc;
  }, {});

  return result;
}

export function monthly_number_of_voters(config, data, chart) {
  let aggregatedVotes = aggregateDistinctByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];

  Object.entries(aggregatedVotes).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedMonths = months.map(month => {
    const originalDate = new Date(month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct Voters', 'Delegated Voters', 'Number of Referenda']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Distinct Voters',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct Voters',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Delegated Voters',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Number of Referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  chart.setOption(option, true);
}
function aggregateTokensByMonthAndType(data) {
  const result = data.reduce((acc, item) => {
    if (!acc[item.month]) {
      acc[item.month] = {direct: 0, delegated: 0, number_of_referenda: 0};
    }
    if (item.vote_type === 'direct') {
      acc[item.month].direct += parseInt(item.sum_of_tokens, 10);
    } else if (item.vote_type === 'delegated') {
      acc[item.month].delegated += parseInt(item.sum_of_tokens, 10);
    }
    acc[item.month].number_of_referenda = parseInt(item.number_of_referenda, 10);

    return acc;
  }, {});

  return result;
}

export function monthly_capital_no_conviction(config, data, chart) {
  let aggregatedTokens = aggregateTokensByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];

  Object.entries(aggregatedTokens).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the months
  const formattedMonths = months.map(month => {
    const originalDate = new Date(month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct', 'Delegated', 'Number of referenda']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Votes in DOT',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Delegated',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Number of referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  chart.setOption(option, true);
}

export function monthly_voting_power_with_conviction(config, data, chart) {
  let aggregatedVotes = aggregateTokensByMonthAndType(data);
  let directVoters = [];
  let delegatedVoters = [];
  let numberOfReferendas = [];
  let months = [];

  Object.entries(aggregatedVotes).forEach(([month, data]) => {
    months.push(month);
    directVoters.push(data.direct);
    delegatedVoters.push(data.delegated);
    numberOfReferendas.push(data.number_of_referenda);
  });

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the months
  const formattedMonths = months.map(month => {
    const originalDate = new Date(month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Direct', 'Delegated', 'Number of referenda']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Votes in DOT',
      },
      {
        type: 'value',
        name: 'Number of Referenda',
      }
    ],
    series: [
      {
        name: 'Direct',
        type: 'bar',
        data: directVoters,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Delegated',
        type: 'bar',
        data: delegatedVoters,
        color: colors.neutral,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Number of referenda',
        type: 'line',
        yAxisIndex: 1,
        data: numberOfReferendas,
        color: colors.emphasis
      }
    ]
  };

  chart.setOption(option, true);
}

export function number_monthly_tokens_voted_by_direction(config, data, chart) {
  var colorScheme = {
    'nay': colors.failure,
    'aye': colors.success,
    'split': colors.emphasis
  };

  // Extract and format unique months
  let uniqueMonths = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the unique months
  const formattedMonths = uniqueMonths.map(month => {
    const originalDate = new Date(month);
    return dateFormatter.format(originalDate);
  });

  var aggregatedData = {};
  uniqueMonths.forEach(month => {
    data.forEach(item => {
      if (item.month.slice(0, 10) === month) {
        if (!aggregatedData[month]) {
          aggregatedData[month] = {};
        }
        if (!aggregatedData[month][item.vote_direction]) {
          aggregatedData[month][item.vote_direction] = 0;
        }
        var sumOfTokens = item.sum_of_tokens === '' ? 0 : parseFloat(item.sum_of_tokens);
        aggregatedData[month][item.vote_direction] += sumOfTokens;
      }
    });
  });

  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
    Object.keys(aggregatedData[month]).forEach(vote_direction => {
      if (!seriesData[vote_direction]) {
        seriesData[vote_direction] = new Array(uniqueMonths.length).fill(0);
      }
      var monthIndex = uniqueMonths.indexOf(month);
      seriesData[vote_direction][monthIndex] = aggregatedData[month][vote_direction];
    });
  });

  var series = [];
  Object.keys(seriesData).forEach(function(vote_direction) {
    series.push({
      name: vote_direction,
      type: 'bar',
      stack: 'total',
      data: seriesData[vote_direction],
      itemStyle: {
        color: colorScheme[vote_direction],
        opacity: 0.8
      }
    });
  });

  var option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'shadow'
      },
      formatter: customFormatter
    },
    legend: {
      data: Object.keys(seriesData),
      bottom: '0%',
      left: 'center'
    },
    graphic: watermark,
    grid: {
      left: '3%',
      right: '4%',
      bottom: '9%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: {
      name: 'DOT',
      type: 'value'
    },
    series: series
  };

  chart.setOption(option, true);
}

export function number_of_parachains(config, data, chart) {
  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the months
  const formattedMonths = data.map(item => {
    const originalDate = new Date(item.month.slice(0, 10));
    return dateFormatter.format(originalDate);
  });

  let numberOfParachains = data.map(item => parseFloat(item.number_of_parchains));

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Number of Parachains']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Number of Parachains',
      },
    ],
    series: [
      {
        name: 'Number of Parachains',
        type: 'line',
        areaStyle: {
          color: colors.polkadot,
          opacity: 0.5
        },
        data: numberOfParachains,
        color: colors.polkadot 
      },
    ]
  };

  chart.setOption(option, true);
}

export function xcm_distinct_hrmp_channels(config, data, chart) {
  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Extract and format unique months
  let months = data.map(item => item.month.slice(0, 10));
  const formattedMonths = months.map(month => {
    const originalDate = new Date(month);
    return dateFormatter.format(originalDate);
  });

  let numberOfHRMPChannels = data.map(item => parseFloat(item.number_of_hrmp_channels));

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['HRMP Channels']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'HRMP Channels',
      },
    ],
    series: [
      {
        name: 'HRMP Channels',
        type: 'line',
        areaStyle: {
          color: colors.emphasis,
          opacity: 0.5
        },
        data: numberOfHRMPChannels,
        color: colors.emphasis
      },
    ]
  };

  chart.setOption(option, true);
}

export function xcm_parachain_message_usage_hrmp_senders(config, data, chart) {
  data.reverse();

  var colorScheme = {
    'polkadot': colors.polkadot,
  };

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Extract and format unique months
  let uniqueMonths = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  const formattedMonths = uniqueMonths.map(month => {
    const originalDate = new Date(month);
    return dateFormatter.format(originalDate);
  });

  var aggregatedData = {};
  uniqueMonths.forEach(month => {
    data.forEach(item => {
      if (item.month.slice(0, 10) === month) {
        if (!aggregatedData[month]) {
          aggregatedData[month] = {};
        }
        if (!aggregatedData[month][item.chain]) {
          aggregatedData[month][item.chain] = 0;
        }
        aggregatedData[month][item.chain] += parseFloat(item.method_count);
      }
    });
  });

  var totalPerMonth = {};
  uniqueMonths.forEach(month => {
    totalPerMonth[month] = 0;
    Object.keys(aggregatedData[month]).forEach(chain => {
      totalPerMonth[month] += aggregatedData[month][chain];
    });
  });

  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
    Object.keys(aggregatedData[month]).forEach(chain => {
      if (!seriesData[chain]) {
        seriesData[chain] = new Array(uniqueMonths.length).fill(0);
      }
      var monthIndex = uniqueMonths.indexOf(month);
      seriesData[chain][monthIndex] = aggregatedData[month][chain];
    });
  });

  var series = [];
  var chains = Object.keys(seriesData);
  chains.forEach(function(chain, index) {
    series.push({
      name: chain,
      type: 'bar',
      stack: 'total',
      data: seriesData[chain],
      itemStyle: {
        color: colorScheme[chain],
        opacity: 0.8
      },
      label: {
        show: index === chains.length - 1,
        position: 'top',
        formatter: function(params) {
          var month = params.name;
          var total = totalPerMonth[month];
          return (total / 1000).toFixed(1) + 'K';
        }
      }
    });
  });

  var sortedSeriesData = {};
  uniqueMonths.forEach(month => {
    let monthData = [];
    Object.keys(aggregatedData[month]).forEach(chain => {
      monthData.push({
        chain: chain,
        value: aggregatedData[month][chain]
      });
    });

    monthData.sort((a, b) => b.value - a.value);

    sortedSeriesData[month] = monthData;
  });

  var option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'shadow'
      },
      formatter: customFormatter
    },
    legend: {
      data: Object.keys(seriesData),
      left: 'center',
      bottom: '0%',
    },
    graphic: watermark,
    grid: { left: '3%', right: '4%', bottom: '20%', containLabel: true },
    xAxis: [
      {
        type: 'category',
        data: formattedMonths, // Use formatted months
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: {
      name: 'HRMP Senders',
      type: 'value'
    },
    series: series
  };

  chart.setOption(option, true);
}

export function github_commits(config, data, chart) {
  data.reverse()
  
  let weekStart = Array.from(new Set(data.map(item => item.week_start.slice(0, 10))));
  let numberOfCommits = data.filter(item => item.type == "ecosystem").map(item => parseFloat(item.commit_count));
  
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['GitHub: Commits']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: weekStart,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'GitHub: Commits',
      },
    ],
    series: [
      {
        name: 'GitHub: Commits',
        type: 'bar',
        data: numberOfCommits,
        color: colors.emphasis,
        itemStyle: {
          opacity: 0.8
        }
      },
    ]
  };
  
  chart.setOption(option, true);
}
  
export function github_active_developers(config, data, chart) {
  data.reverse()
  
  let weekStart = Array.from(new Set(data.map(item => item.week_start.slice(0, 10))));
  let numberOfAuthors = data.filter(item => item.type == "ecosystem").map(item => parseFloat(item.author_count));
  
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['GitHub: Active Developers']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: weekStart,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'GitHub: Active Developers',
      },
    ],
    series: [
      {
        name: 'GitHub: Active Developers',
        type: 'bar',
        data: numberOfAuthors,
        color: colors.heat,
        itemStyle: {
          opacity: 0.8
        }
      },
    ]
  };
  
  chart.setOption(option, true);
}
  
export function github_issues_opened_and_closed(config, data, chart) {
  data.reverse()
  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  let issuesClosed = data.filter(item => item.type == "Issues Closed").map(item => parseFloat(item.type_count));
  let issuesOpened = data.filter(item => item.type == "Issues Opened").map(item => parseFloat(item.type_count));
  

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedDates = data.map(item => {
    const originalDate = new Date(item.month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Issues Opened', 'Issues Closed']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'voters',
      }
    ],
    series: [
      {
        name: 'Issues Opened',
        type: 'bar',
        data: issuesOpened,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Issues Closed',
        type: 'bar',
        data: issuesClosed,
        color: colors.success,
        itemStyle: {
          opacity: 0.8
        }
      }
    ]
  };
  
  chart.setOption(option, true);
}
  
export function github_issues_merged_pull_requests(config, data, chart) {
  let months = Array.from(new Set(data.map(item => item.month.slice(0, 10))));
  months.sort((a, b) => new Date(a) - new Date(b));
  
  let issuesMerged = data.map(item => parseInt(item.total_pull_id_count));
  

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedDates = data.map(item => {
    const originalDate = new Date(item.month);
    return dateFormatter.format(originalDate);
  });

  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Issues Merged']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Total',
      }
    ],
    series: [
      {
        name: 'Issues Merged',
        type: 'bar',
        data: issuesMerged,
        color: colors.success,
        itemStyle: {
          opacity: 0.8
        }
      }
    ]
  };
  
  chart.setOption(option, true);
}
  
// export function github_unique_authors_and_orgs_commits(config, data, chart) {
//   data.reverse()
  
//   let org = data.map(item => item.org);
//   let developers = data.map(item => parseFloat(item.developers));
//   let commits = data.map(item => parseFloat(item.commits));
  
//   var option = {
//     legend: {
//       bottom: '0%',
//       left: 'center',
//       data: ['Developers', 'Commits']
//     },
//     graphic: watermark,
//     grid: {
//       left: '3%',
//       right: '4%',
//       bottom: '5%',
//       containLabel: true
//     },
//     tooltip: {
//       trigger: 'axis',
//       backgroundColor: tooltipBackgroundColor,
//       axisPointer: {
//         type: 'cross'
//       },
//       formatter: customFormatter
//     },
//     xAxis: [
//       {
//         type: 'category',
//         data: org,
//         axisPointer: {
//           type: 'shadow'
//         },
//         axisLabel: {
//           interval: 0, // Show all labels
//           rotate: 45, // Optional: if labels overlap, rotate them
//         }
//       }
//     ],
//     yAxis: [
//       {
//         type: 'value',
//         name: 'Developers',
//       },
//       {
//         type: 'value',
//         name: 'Commits',
//       }
//     ],
//     series: [
//       {
//         name: 'Developers',
//         type: 'bar',
//         data: developers,
//         color: colors.emphasis,
//         itemStyle: {
//           opacity: 0.8
//         }
//       },
//       {
//         name: 'Commits',
//         type: 'line',
//         data: commits,
//         yAxisIndex: 1,
//         color: colors.polkadot
//       }
//     ]
//   };
  
//   chart.setOption(option, true);
// }
  
export function stackexchange_user_growth(config, data, chart) {
  let sortedData = data.sort((a, b) => new Date(a.creation_date) - new Date(b.creation_date));
  
  let months = sortedData.map(item => item.creation_date.slice(0, 10));
  let numUsers = sortedData.map(item => parseFloat(item.num_users));
  let runningTotals = sortedData.map(item => parseFloat(item.running_total));
  
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['StackExchange Users', 'Running Totals']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Total',
      },
      {
        type: 'value',
        name: 'Running Totals',
        position: 'right'
      }
    ],
    series: [
      {
        name: 'StackExchange Users',
        type: 'bar',
        data: numUsers,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
      },
      {
        name: 'Running Totals',
        type: 'line',
        yAxisIndex: 1,
        data: runningTotals,
        smooth: true
      }
    ]
  };
  
  chart.setOption(option, true);
}
  
export function stackexchange_top_tags(config, data, chart) {
  var option = {
    tooltip: {
      backgroundColor: tooltipBackgroundColor,
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    graphic: watermark,
    series: [
      {
        name: 'Tag',
        type: 'pie',
        radius: ['40%', '70%'],
        data: data.map((item, index) => {
          return {
            value: item.tags_count,
            name: item.tags
          };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
  
  chart.setOption(option, true);
}
  
export function stackexchange_activity_eoy(config, data, chart) {
  var colorScheme = {
    'post-question': colors.polkadot,
    'post-answer': colors.success,
    'suggested-edit': colors.emphasis,
    'badge': colors.heat
  };
  
  let months = Array.from(new Set(data.map(item => item.date.slice(0, 10))));
  
  var aggregatedData = {};
  months.forEach(month => {
      data.forEach(item => {
          if (item.date.slice(0, 10) === month) {
              if (!aggregatedData[month]) {
                  aggregatedData[month] = {};
              }
              if (!aggregatedData[month][item.timeline_type_post]) {
                  aggregatedData[month][item.timeline_type_post] = 0;
              }
              aggregatedData[month][item.timeline_type_post] += parseFloat(item.number_of_engagements);
          }
      });
  });
  
  var seriesData = {};
  Object.keys(aggregatedData).forEach(month => {
      Object.keys(aggregatedData[month]).forEach(timeline_type_post => {
          if (!seriesData[timeline_type_post]) {
              seriesData[timeline_type_post] = new Array(months.length).fill(0);
          }
          var monthIndex = months.indexOf(month);
          seriesData[timeline_type_post][monthIndex] = aggregatedData[month][timeline_type_post];
      });
  });
  
  var series = [];
  Object.keys(seriesData).forEach(function(timeline_type_post) {
      series.push({
          name: timeline_type_post,
          type: 'bar',
          stack: 'total',
          data: seriesData[timeline_type_post],
          itemStyle: {
              color: colorScheme[timeline_type_post],
              opacity: 0.8
          }
      });
  });
  
  var option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    legend: {
      data: Object.keys(seriesData),
      bottom: '0%',
      left: 'center'
    },
    graphic: watermark,
    grid: {
      left: '3%',
      right: '4%',
      bottom: '9%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: {
      name: 'Engagements',
      type: 'value'
    },
    series: series
  };
  
  chart.setOption(option, true);
}
  
export function polkadot_asset_hub_usdc(config, data, chart) {
  data.reverse()
  
  let months = data.map(item => item.date.slice(0, 10));
  months.sort((a, b) => new Date(a) - new Date(b));
  

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedDates = data.map(item => {
    const originalDate = new Date(item.date);
    return dateFormatter.format(originalDate);
  });


  let sumUSDC = data.map(item => parseFloat(item.sum_of_usdc));
  
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Sum of USDC']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Sum of USDC',
      },
    ],
    series: [
      {
        name: 'Sum of USDC',
        type: 'line',
        areaStyle: {
          color: colors.success,
          opacity: 0.5
        },
        data: sumUSDC,
        color: colors.success
      },
    ]
  };
  
  chart.setOption(option, true);
}
  
export function sum_of_staked_tokens(config, data, chart) {
  let months = data.map(item => item.date.slice(0, 10));
  let sumStaked = data.map(item => parseFloat(item.sum_of_staked_tokens));
  

  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: config.freq === 'daily' ? '2-digit' : undefined,
  });

  // Format the dates
  const formattedDates = data.map(item => {
    const originalDate = new Date(item.date);
    return dateFormatter.format(originalDate);
  });


  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: ['Sum of Staked DOT']
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Sum of Staked DOT',
        min: 6.5E8,
        max: 7.3E8
      },
    ],
    series: [
      {
        name: 'Sum of Staked DOT',
        type: 'line',
        areaStyle: {
          color: colors.success,
          opacity: 0.5
        },
        data: sumStaked,
        color: colors.success
      },
    ]
  };
  
  chart.setOption(option, true);
}
  
export function qfour_auction_winners(config, data, chart) {
  let projectName = Array.from(new Set(data.map(item => item.project_name_para_id)));
  let auctionIndex = data.map(item => item.auction_index);
  let contributionAmount = data.map(item => parseFloat(item.contribution_amount));
  
  var option = {
    legend: {
      bottom: '0%',
      left: 'center',
      data: auctionIndex
    },
    graphic: watermark,
    tooltip: {
      trigger: 'axis',
      backgroundColor: tooltipBackgroundColor,
      axisPointer: {
        type: 'cross'
      },
      formatter: customFormatter
    },
    xAxis: [
      {
        type: 'category',
        data: projectName,
        axisPointer: {
          type: 'shadow'
        },
        axisLabel: {
          interval: 0, // Show all labels
          rotate: 45, // Optional: if labels overlap, rotate them
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Contribution Amount (DOT)',
      }
    ],
    series: [
      {
        name: 'Contribution Amount',
        type: 'bar',
        data: contributionAmount,
        color: colors.polkadot,
        itemStyle: {
          opacity: 0.8
        }
     }
    ]
  };
  
  chart.setOption(option, true);
}
  
export function parachains_race(config, data, chart) {
  const chainColors = get_chain_colors();
  const title = config.title;
  const yName = config.yName;
  const xName = config.xName;
  const currentTheme = localStorage.getItem('theme') || 'light';
  const speed = xName === 'month' ? 1000 : 150;
  
  const parsedData = data.map(d => ({
    timestamp: d[xName],
    chain: d.chain,
    value: parseFloat(d[yName])
  }));
  
  const cumulativeData = {};
  const allChains = [...new Set(parsedData.map(item => item.chain))];
  const cumulativeValues = {};
  
  allChains.forEach(chain => {
    cumulativeValues[chain] = 0;
  });
  
  parsedData.forEach(item => {
    const { timestamp, chain, value } = item;
    if (!cumulativeData[timestamp]) {
      cumulativeData[timestamp] = {};
    }
    cumulativeValues[chain] += value;
    cumulativeData[timestamp][chain] = cumulativeValues[chain];
  });
  
  const timestamps = [...new Set(parsedData.map(item => item.timestamp))].sort();
  const userLocale = navigator.language || 'en-US';
  const dateFormatter = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: 'short',
    day: xName === 'month' ? undefined : '2-digit',
  });
  
  const formattedTimestamps = timestamps.map(timestamp => dateFormatter.format(new Date(timestamp)));
  
  const chartData = timestamps.map(timestamp => {
    const dataAtTimestamp = { timestamp, formattedTimestamp: dateFormatter.format(new Date(timestamp)) };
    allChains.forEach(chain => {
      dataAtTimestamp[chain] = cumulativeData[timestamp][chain] || 0;
    });
    return dataAtTimestamp;
  });
  
  const option = {
    timeline: {
      axisType: 'category',
      playInterval: speed,
      autoPlay: true,
      loop: false,
      controlStyle: {
        show: false
      },
      data: formattedTimestamps
    },
    options: chartData.map(d => {
      const sortedChains = allChains.map(chain => ({
        chain,
        value: d[chain] || 0
      })).sort((a, b) => b.value - a.value).slice(0, 10);
  
      const categories = sortedChains.map(item => item.chain);
      const seriesData = sortedChains.map(item => ({
        value: item.value,
        name: item.chain,
        itemStyle: {
          color: chainColors[item.chain] || chainColors['other'],
          opacity: 0.8
        },
        label: {
          show: true,
          position: 'right',
          formatter: () => {
            return `{bold|${item.value.toLocaleString(userLocale, { maximumFractionDigits: 0 })}}`;
          },
          rich: {
            bold: {
              fontWeight: 'bold',
              fontSize: 16,
            },
            normal: {
              fontWeight: 'normal',
              fontSize: 16,
            }
          }
        }
      }));
  
      return {
        graphic: watermark,
        title: {
          text: `${title} - ${d.formattedTimestamp}`,
          left: 'center',
          textStyle: {
            fontFamily: 'Unbounded',
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        animationDuration: speed,
        animationDurationUpdate: speed,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear',
        tooltip: {
          trigger: 'axis',
          backgroundColor: tooltipBackgroundColor,
          formatter: function (params) {
            let tooltipText = `Timestamp: ${d.formattedTimestamp}<br/>`;
            params.forEach(param => {
              tooltipText += `${param.seriesName}: ${param.value.toLocaleString(userLocale, { maximumFractionDigits: 0 })}<br/>`;
            });
            return tooltipText;
          }
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: function(value) {
              return value.toLocaleString(userLocale);
            }
          }
        },
        yAxis: {
          type: 'category',
          data: categories,
          inverse: true
        },
        series: [{
          type: 'bar',
          animationDuration: speed,
          animationEasing: 'linear',
          data: seriesData
        }]
      };
    })
  };
  
  chart.setOption(option, true);
}
  
function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
}
  
export function populateTable(config, data) {
  const tableBody = document.getElementById(config.elementId).getElementsByTagName('tbody')[0];
  
  // Clear existing table rows
  tableBody.innerHTML = '';
  
  data.forEach(item => {
    const row = tableBody.insertRow();
    const cellDate = row.insertCell(0);
    const cellNetwork = row.insertCell(1);
    const cellChain = row.insertCell(2);
    const cellVersion = row.insertCell(3);
  
    cellDate.textContent = formatDate(item.date);
    cellNetwork.textContent = item.relay_chain;
    cellChain.textContent = item.chain;
    cellVersion.textContent = item.version;
  });
}
  
export function populateMetadata(config, data) {
  const tableBody = document.getElementById(config.elementId).getElementsByTagName('tbody')[0];
  
  // Clear existing table rows
  tableBody.innerHTML = '';
  
  let dataExists = false;

  data.forEach(item => {
    if (config.check === 'all' || item.CheckMetadataHash === config.check) {
      const row = tableBody.insertRow();
      const cellDate = row.insertCell(0);
      const cellNetwork = row.insertCell(1);
      const cellChain = row.insertCell(2);
      const cellCheck = row.insertCell(3);
      const cellVersions = row.insertCell(4);
      const cellMetadata = row.insertCell(5);
    
      cellDate.textContent = formatDate(item.date);
      cellNetwork.textContent = item.relay_chain;
      cellChain.textContent = item.chain;
      cellCheck.textContent = item.CheckMetadataHash;
      cellVersions.textContent = item.metadata_versions;
      cellMetadata.textContent = item.metadata;
  
      dataExists = true;
  
    }
  });

  // If no data exists, show a message
  if (dataExists == false) {
    const row = tableBody.insertRow();
    const cell = row.insertCell(0);
    cell.colSpan = 6;
    cell.textContent = 'No data available';
    cell.style.textAlign = 'center';
  }
}

function get_chain_colors() {
  return {
    'other': '#CCCCCC',
    'polkadot': '#E6007A',
    'aleph-zero': '#f0e68c',
    'astar': '#56F39A',
    'aventus': '#552BBF',
    'equilibrium': '#0000ff',
    'moonbeam': '#33D6FF',
    'mythos': '#a020f0',
    'neuroweb': '#AA7BDB',
    'phala': '#FF7F50',
    'acala': '#2f4f4f',
    'ajuna': '#556b2f',
    'bifrost': '#8b4513',
    'bitgreen': '#6b8e23',
    'centrifuge': '#2e8b57',
    'clover': '#228b22',
    'collectives': '#8b0000',
    'composable': '#483d8b',
    'crust': '#008080',
    'darwinia': '#b8860b',
    'efinity': '#4682b4',
    'energyweb': '#a020f0',
    'frequency': '#d2691e',
    'hydradx': '#9acd32',
    'integritee': '#4b0082',
    'interlay': '#32cd32',
    'invarch': '#00ff00',
    'kapex': '#8fbc8f',
    'kilt-spiritnet': '#8b008b',
    'kylin': '#b03060',
    'litentry': '#48d1cc',
    'manta': '#9932cc',
    'moonsama': '#00ff7f',
    'mythos': '#dc143c',
    'nodle': '#ff0000',
    'parallel': '#ff8c00',
    'pendulum': '#ffd700',
    'polemic': '#00ffff', 
    'polkadex': '#ffff00',
    'polkadot-bridgehub': '#f08080',
    'polkadot-hashed': '#c71585',
    'polkadot-t3rn': '#0000cd',
    'Polkadot Asset Hub': '#00B2FF',
    'unique': '#dda0dd',
    'zeitgeist': '#dc143c',
    'kusama': '#ff0000',
    'altair': '#ffff00',
    'amplitude': '#00bfff',
    'bajun': '#b03060',
    'basilisk': '#800080',
    'bridgehub': '#f4a460',
    'calamari': '#f0e68c',
    'crab': '#8fbc8f',
    'dorafactory': '#daa520',
    'encointer': '#9acd32',
    'ipci': '#d2691e',
    'kabocha': '#000080',
    'karura': '#9370db',
    'khala': '#0000ff',
    'kintsugi': '#fa8072',
    'mangata': '#6495ed',
    'moonriver': '#a020f0',
    'parallel-heiko': '#ff6347',
    'picasso': '#adff2f',
    'pichiu': '#4682b4',
    'quartz': '#008b8b',
    'robonomics': '#008000',
    'rococo': '#dc143c',
    'shiden': '#483d8b',
    'sora': '#7f0000',
    'subsocial': '#7cfc00',
    'Kusama Asset Hub': '#556b2f',
    'tinker': '#d3d3d3',
    'turing': '#696969'
    }
  }  

  // '#1e90ff', // dodgerblue
  // '#dda0dd', // plum
  // '#90ee90', // lightgreen
  // '#ff1493', // deeppink
  