import * as echarts from 'echarts/core';

const contrastColor = '#121212';
const backgroundColor = '#FFFFFF';
export const theme = {
    darkMode: false,

    // var colorPalette = [
    //     '#C1232B',
    //     '#27727B',
    //     '#FCCE10',
    //     '#E87C25',
    //     '#B5C334',
    //     '#FE8463',
    //     '#9BCA63',
    //     '#FAD860',
    //     '#F3A43B',
    //     '#60C0DD',
    //     '#D7504B',
    //     '#C6E579',
    //     '#F4E001',
    //     '#F0805A',
    //     '#26C0C0'
    // ];    
    title: {
        textStyle: {
            fontWeight: 'normal',
            color: '#27727B'
        }
    },

    visualMap: {
        color: ['#C1232B', '#FCCE10']
    },

    toolbox: {
        iconStyle: {
            borderColor: '#C1232B'
        }
    },

    tooltip: {
        backgroundColor: 'rgba(179,206,229,0.5)',
        axisPointer: {
            type: 'line',
            lineStyle: {
                color: '#27727B',
                type: 'dashed'
            },
            crossStyle: {
                color: '#27727B'
            },
            shadowStyle: {
                color: 'rgba(200,200,200,0.3)'
            }
        }
    },

    dataZoom: {
        dataBackgroundColor: 'rgba(181,195,52,0.3)',
        fillerColor: 'rgba(181,195,52,0.2)',
        handleColor: '#27727B'
    },

    categoryAxis: {
        axisLine: {
            lineStyle: {
                color: '#27727B'
            }
        },
        splitLine: {
            show: false
        }
    },

    valueAxis: {
        axisLine: {
            show: false
        },
        splitArea: {
            show: false
        },
        splitLine: {
            lineStyle: {
                color: ['#ccc'],
                type: 'dashed'
            }
        }
    }
};

echarts.registerTheme('light', theme);
